.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-form {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .login-form .form-group {
    margin-bottom: 15px;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .login-form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .login-form button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .login-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .login-form button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .signup-link {
    margin-top: 10px;
    text-align: center;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  .signup-link span:hover {
    color: #0056b3;
  }
  